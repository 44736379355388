import {
    useParams,    
} from "react-router-dom";
import { useSnackbar } from 'notistack';
import { useState } from "react";
import { useEffect } from 'react';
import sitesService from '../../services/sites.service';
import PageNotFound from "../../pages/404"
import SiteForm from "../../components/sites/form"
import BackdropApp from "../../components/BackdropApp";

const EditSite = () => {
    let { siteId } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const [editedSite, seteditedSite] = useState("")
    const [loaded, setloaded] = useState(false)

    const ambilSiteEdited = async () => {
        const data = await sitesService.get(siteId)
        if (!data.success) enqueueSnackbar(data.message, {
            variant: 'error',
        });
        seteditedSite(data.payload)
        setloaded(true)
    }

    useEffect(() => {
        ambilSiteEdited()
    }, [])


    if (editedSite) {
        return (
            <>
                <SiteForm sites={editedSite} />
            </>
        )
    } else {
        if (loaded) {
            return (
                <>
                    <PageNotFound />
                </>
            )
        } else {
            return (
                <>                
                    <BackdropApp loaded={loaded} />
                </>
            )

        }
    }
}

export default EditSite