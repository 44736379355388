import * as React from 'react'
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux";
import { bindActionCreators } from 'redux'
import { actionCreators } from "../../state/index"
import BackupDataService from '../../services/backup.service'
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import FolderButton from '../../components/folderButton';
import { Helmet } from "react-helmet-async";
import { Typography } from '@mui/material';
import BackdropApp from '../../components/BackdropApp';

const IndexBackup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dir = useSelector((state) => state.backup);
    const { setBackupFile } = bindActionCreators(actionCreators, dispatch);
    const [loaded, setloaded] = React.useState(false)

    async function getData(req) {
        let data = await BackupDataService.findFile(req)

        setBackupFile(data.payload)
        setloaded(true)
    }

    React.useEffect(() => {
        if (!loaded) {
            getData()
        }

    }, [loaded])

    function handleDirectoryClick(e) {
        navigate(e)
    }

    if (!loaded) return <BackdropApp />
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Helper - Browse Backup</title>
            </Helmet>
            <Typography variant='h6'>Browse backup : Pilih Tahun</Typography>
            <Paper sx={{ minHeight: '70vh', maxWidth: '100%', my: 1, mx: 'auto', p: 2 }}>
                <Grid container spacing={2}>
                    {
                        dir?.map((e) => {
                            return <Grid key={e} item md={3} xs={12}>
                                <FolderButton name={e} event={() => handleDirectoryClick(e)} />
                            </Grid>
                        })
                    }
                </Grid>
            </Paper>
        </>
    )
}

export default IndexBackup