import { Helmet } from "react-helmet-async";

const PageNotFound = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Helper - Page Not Found</title>
            </Helmet>
            <h1>404 Page Not Found</h1>
        </>
    )
}

export default PageNotFound