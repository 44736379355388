import CustomerForm from "../../components/customers/form"

const CreateCustomer = () => {
    return (
        <>
            <CustomerForm />
        </>
    )
}

export default CreateCustomer