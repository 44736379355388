import http from "../common/http-common";

class ClientDataService {
  getAll() {
    const data = http.get("/client/read/")

    return data
  }

  getBts() {
    const data = http.get("/client/read/bts")

    return data
  }

  get(id) {
    const data = http.get(`/client/read/${id}`)

    return data
  }

  create(form) {
    const data = http.post("/client/create", form)

    return data
  }

  update(form, id) {    
    const data = http.put(`/client/${id}/update`, form)

    return data
  }

  delete(id) {
    const data = http.delete(`/client/${id}/delete`)

    return data
  }

}

export default new ClientDataService();