export const setSidebarOpen = (data) => {    
    return (dispatch) => {
        dispatch({
            type: "sidebaropen",
            payload: data
        });
    }
}
export const setBackupFile = (data) => {    

    return (dispatch) => {
        dispatch({
            type: "browseFile",
            payload: data
        });
    }
}
export const setLoggedIn = (data) => {    
    return (dispatch) => {
        dispatch({
            type: "loggedin",
            payload: data
        });
    }
}
export const setAccount = (data) => {    
    return (dispatch) => {
        dispatch({
            type: "emailLogin",
            payload: data
        });
    }
}
export const setBTS = (data) => {    
    return (dispatch) => {
        dispatch({
            type: "getsite",
            payload: data
        });
    }
}
export const setDevices = (data) => {    
    return (dispatch) => {
        dispatch({
            type: "getdevices",
            payload: data
        });
    }
}
export const setLinks = (data) => {    
    return (dispatch) => {
        dispatch({
            type: "getlinks",
            payload: data
        });
    }
}