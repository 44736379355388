import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import authService from '../../services/auth.service';
import { useSnackbar } from 'notistack';
import { bindActionCreators } from 'redux'
import { actionCreators } from "../../state/index"
import { useDispatch } from "react-redux";
import BackdropApp from '../../components/BackdropApp';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import { Helmet } from "react-helmet-async";

const LoginPage = () => {
    console.log(process.env.REACT_APP_AUTH_SERVER)
    const { enqueueSnackbar } = useSnackbar();
    const [loaded, setloaded] = React.useState(true)
    const dispatch = useDispatch();
    const { setLoggedIn } = bindActionCreators(actionCreators, dispatch);

    const [values, setValues] = React.useState({
        email: '',
        password: '',
        showPassword: false,
    });
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (e) => {
        setloaded(false)
        e.preventDefault();
        const login = await authService.login(values)
        if (login.success) {
            localStorage.setItem('access_token', login.payload.access_token)
            enqueueSnackbar(login.message, {
                variant: 'success',
            });
            setloaded(true)
            setLoggedIn(login.success)
        } else {
            setloaded(true)
            if (Array.isArray(login.validationErrors)) {
                login.validationErrors.map((e, i) => {
                    enqueueSnackbar(e.msg, {
                        variant: 'error',
                    });
                })
            } else {
                enqueueSnackbar(login.errors?.message, {
                    variant: 'error',
                });
            }
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Helper - Login</title>
            </Helmet>
            <Stack
                component="form"
                sx={{
                    width: '80%',
                    padding: 2,
                }}
                alignContent="center"
                alignItems="center"
                spacing={2}
                noValidate
                autoComplete="off"
            >
                <Typography variant="h5">Welcome !</Typography>
                <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">email</InputLabel>
                    <Input
                        id="email"
                        type="text"
                        value={values.email}
                        onChange={handleChange('email')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton disabled
                                >
                                    {<EmailIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl fullWidth variant="standard">
                    <Button onClick={handleSubmit} variant="contained">Login</Button>
                </FormControl>
                <Typography align="center">
                    Lupa password? Silahkan <Link to="/forget-password">reset password</Link>
                </Typography>
            </Stack>
            {!loaded ? <BackdropApp /> : null}
        </>
    )
}

export default LoginPage