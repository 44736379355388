
import { combineReducers } from "redux";
import siteReducer from "./siteReducer";
import deviceReducer from "./deviceReducer";
import menuReducer from "./menuReducer";
import authReducer from "./authReducer";
import emailReducer from "./emailReducer";
import linkReducer from "./linkReducer";
import backupReducer from "./backupFileReducer";

const reducers = combineReducers({
    sites: siteReducer,    
    menu: menuReducer,
    auth: authReducer,
    link: linkReducer,
    email: emailReducer,
    devices:deviceReducer,
    backup:backupReducer
})

export default reducers
