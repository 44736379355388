import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import deviceService from '../../services/device.service';
import siteService from '../../services/sites.service';
import { useSnackbar } from 'notistack';
import { useState } from "react";
import RouterIcon from '@mui/icons-material/Router';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import {
    useLocation,
    useNavigate,
} from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import clientService from '../../services/client.service';
import PushPinIcon from '@mui/icons-material/PushPin';

export default function LinkForm(props) {
    const [gateway, setgateway] = useState([{}])
    const [site, setsite] = useState([{}])
    const [InputClient, setInputClient] = useState({})

    let navigate = useNavigate();
    let location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const clients = props.clients

    let from = location.state?.from?.pathname || "/clients";

    useEffect(() => {
        ambilDevices()
        
        if (clients !== undefined) {
            return setInputClient({
                id: clients?.id,
                name: clients?.name,
                gateway: clients?.gateway,
                ip: clients?.host_device?.ip,
                is_wireless: clients?.is_wireless ? 'wireless' : 'wired',
                latitude: clients?.host_device?.site?.latitude,
                longitude: clients?.host_device?.site?.longitude,
                address: clients?.host_device?.site?.address,
            })
        }
    }, [clients])

    const handleChange = (e) => {
        if (e.target.type === 'checkbox' && e.target.name === 'is_wireless') {
            setInputClient({
                ...InputClient,
                [e.target.name]: e.target.checked ? 'wireless' : 'wired'
            })
        } else {
            setInputClient({
                ...InputClient,
                [e.target.name]: e.target.value
            })
        }
    }

    const ambilDevices = async () => {
        const data = await deviceService.getAll()
        const site = await siteService.getAll()

        let gatewayDevice = data.payload?.filter((e) => {
            let x = e.device_category !== 1 && e.device_category !== 6

            return x;
        })
        let sitesFiltered = site.payload?.filter((e) => {
            let x = e.category !== 2

            return x;
        })
        setgateway(gatewayDevice)
        setsite(sitesFiltered)
    }

    const handleSubmit = async () => {
        setInputClient({
            ...InputClient,
        })

        if (clients) {
            const api = await clientService.update(InputClient, clients.id)

            if (api.success) {
                enqueueSnackbar(api.message, {
                    variant: 'success',
                });
                setInputClient({
                    name: "",
                    gateway: "",
                    host: "",
                    is_wireless: "",
                })
                navigate(from, { replace: true });
            } else {
                if (Array.isArray(api.validationErrors)) {
                    api.validationErrors.map((e, i) => {
                        return enqueueSnackbar(e.msg, {
                            variant: 'error',
                        });
                    })
                } else {
                    enqueueSnackbar(api.errors, {
                        variant: 'error',
                    });
                }
            }
        } else {
            const api = await clientService.create(InputClient)

            if (api.success) {
                enqueueSnackbar(api.message, {
                    variant: 'success',
                });
                setInputClient({
                    name: "",
                    gateway: "",
                    host: "",
                    is_wireless: "",
                })
                navigate(from, { replace: true });
            } else {
                if (Array.isArray(api.validationErrors)) {
                    return api.validationErrors.map((e, i) => {
                        enqueueSnackbar(e.msg, {
                            variant: 'error',
                        });
                    })
                } else {
                    enqueueSnackbar(api.errors?.message, {
                        variant: 'error',
                    });
                }
            }
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item md={3}>
            </Grid>
            <Grid item md={6}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="nama">Nama Client</InputLabel>
                            <Input
                                onChange={handleChange}
                                name="name"
                                label="Nama client"
                                id="nama"
                                value={InputClient?.name ? InputClient?.name : ""}
                                placeholder="Nama client"
                                size="small"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton disabled
                                        >
                                            {<AccountCircleIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel id="gateway">Pilih perangkat gateway</InputLabel>
                            <Select
                                native                                
                                labelId="gatewayDevice"
                                id="selectgatewayDevice"
                                value={InputClient?.gateway ? InputClient?.gateway : 0}
                                label="Pilih Perangkat"
                                onChange={handleChange}
                                name="gateway"
                                
                            >
                                <option aria-label="None" value={0} children="- Pilih gateway -" disabled />
                                {
                                    site?.map((s, n) => {
                                        return (
                                            <optgroup key={n+'-gw'} label={s.name}>
                                                {
                                                    gateway?.map((e, i) => {
                                                        if (e.site_id === s.id) {
                                                            return <option key={e.id+e.name} value={e.id}>{e.name}</option>
                                                        }
                                                    })}
                                            </optgroup>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="ip">IP Address</InputLabel>
                            <Input
                                onChange={handleChange}
                                name="ip"
                                label="IP Address"
                                id="ip"
                                value={InputClient?.ip ? InputClient?.ip : ""}
                                placeholder="IP Address v4"
                                size="small"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton disabled
                                        >
                                            {<RouterIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <FormControlLabel control={<Switch checked={InputClient?.is_wireless === "wireless" ? true : false} onChange={handleChange} name="is_wireless" />} label="Wireless Link" />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="latitude">Garis Lintang</InputLabel>
                            <Input
                                onChange={handleChange}
                                value={InputClient?.latitude ? InputClient?.latitude : ""}
                                name="latitude"
                                label="Decimal latitude"
                                id="latitude"
                                placeholder="Masukkan garis lintang dalam decimal"
                                variant="filled"
                                size="small"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton disabled
                                        >
                                            {<PushPinIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="standard-adornment-password">Garis Bujur</InputLabel>
                            <Input
                                onChange={handleChange}
                                value={InputClient?.longitude ? InputClient?.longitude : ""}
                                name="longitude"
                                label="Decimal longitude"
                                id="longitude"
                                placeholder="Masukkan garis bujur dalam decimal"
                                variant="filled"
                                size="small"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton disabled
                                        >
                                            {<PushPinIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <TextareaAutosize
                                minRows={5}
                                onChange={handleChange}
                                name="address"
                                value={InputClient?.address ? InputClient.address : ""}
                                label="Alamat"
                                id="alamat"
                                placeholder="alamat atau patokan"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Button fullWidth variant="contained" onClick={handleSubmit} aria-label="edit" color="primary">
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={3}>
            </Grid>
        </Grid>
    );
}