import * as React from 'react';
import BackupDataService from "../../services/backup.service"
import { useEffect, useState } from 'react'
import SiteTableAll from "../../components/GeneralTable";
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import Backdrop from '@mui/material/Backdrop';
import { IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import { Helmet } from "react-helmet-async";

const columns = [
    { id: 'number', label: 'No.', minWidth: 20 },    
    { id: 'name', label: 'Name', minWidth: 100 },
    {
        id: 'log',
        label: 'Log',
        align: 'left',
        minWidth: 400
    },
    {
        id: 'timestamp',
        label: 'Timestamp',
        align: 'left',
        minWidth: 70,
        maxWidth: 70,
    },    
];


const LogBackup = () => {    
    const [loaded, setloaded] = useState(false)
    const [search, setsearch] = useState("")        
    const [backupLog, setbackupLog] = useState("")        
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();    

    function createData(data) {
        let array = [];
        for (let i = 0; i < data.length; i++) {
            const e = data[i]; 
            let timestamp = new Date(e.updatedAt)
            
            array.push({
                number: i + 1,                
                name: e.name,
                log:e.log,
                timestamp:timestamp.toLocaleString()        
            })

        }
        return array
    }
    async function getData() {
        const x = await BackupDataService.getAll()

        if (x.success === false) {
            enqueueSnackbar(x.errors?.message, {
                variant: 'error',
            })
            if (x.errors?.message === "Email unverified") {
                navigate("/resend-verify")
            } else {
                navigate("/error")
            }
        } else {
            const array = createData(x.payload) 
            setbackupLog(array)           
            setloaded(true)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const handleSearch = (event) => {
        setsearch(event.target.value)
    }
    const submitSearch = async () => {
        let res = await BackupDataService.getAll()
        res = createData(res.payload)
        let filtered = res.filter((e) => {
            let x = Object.values(e.name).join([""]).toLowerCase().includes(search.toLowerCase())
                || Object.values(e.log).join([""]).toLowerCase().includes(search.toLowerCase())
                || Object.values(e.timestamp).join([""]).toLowerCase().includes(search.toLowerCase())                

            return x;
        })
        setbackupLog([...filtered])
    }


    if (!loaded) {
        return (
            <>
                <Backdrop
                    sx={{ color: '#1976d2', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={!loaded}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        )
    } else {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Helper - Log backup</title>
                </Helmet>
                <h2>Log Backup</h2>
                <Stack
                    m={2}
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    alignItems="flex-start"
                    justifyContent="space-between"
                >
                    <Paper>
                        <InputBase
                            onChange={handleSearch}
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Cari pada tabel"
                            inputProps={{ 'aria-label': 'Cari pada tabel' }}
                        />
                        <IconButton onClick={submitSearch} type="submit" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Stack>
                <SiteTableAll data={backupLog} columns={columns} />
            </>
        )
    }
}

export default LogBackup