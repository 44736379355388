import React, { useCallback, useEffect, useState } from "react";
import L from "leaflet";
import "../../ShowCrimes.css";
import useSupercluster from "use-supercluster";
import { Marker, useMap, Popup } from "react-leaflet";
import { useNavigate } from 'react-router-dom';

const icons = {};
const fetchIcon = (count, size) => {
  if (!icons[count]) {
    icons[count] = L.divIcon({
      html: `<div class="cluster-site-marker" style="width: ${size}px; height: ${size}px;">
        ${count}
      </div>`,
    });
  }
  return icons[count];
};

const cuffs = new L.Icon({
  iconUrl: "/location.png",
  iconSize: [35, 35],
});

function ShowHomepass({ data }) {
  const navigate = useNavigate();
  const maxZoom = 25;
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(12);
  const map = useMap();

  // get map bounds
  function updateMap() {
    const b = map.getBounds();
    setBounds([
      b.getSouthWest().lng,
      b.getSouthWest().lat,
      b.getNorthEast().lng,
      b.getNorthEast().lat,
    ]);

    setZoom(map.getZoom());
  }

  const onMove = useCallback(() => {
    updateMap()
  }, [map]);

  React.useEffect(() => {
    updateMap();
  }, [map]);

  useEffect(() => {
    map.on("move", onMove);
    return () => {
      map.off("move", onMove);
    };
  }, [map, onMove]);

  const points = data?.filter(function (e) {
    if (e.homepassed_koordinat === "unknown") {
      return false; // skip
    }
    return true;
  }).map((e) => {
    let koordinat = e.homepassed_koordinat
    koordinat = koordinat.split(',');
    if (koordinat.length < 2) {
      koordinat = koordinat[0].split(' ');
    }

    return {
      type: "Feature",
      properties: {
        cluster: false,
        siteId: e.id,
        name: e.homepass_id,
        splitter_id: e.splitter_id,
        nama_jalan: e.nama_jalan,
        spliter_distribusi_koordinat: e.spliter_distribusi_koordinat,
        homepassId: e.homepass_id
      },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(koordinat[1]),
          parseFloat(koordinat[0]),
        ],
      },
    }
  });

  const { clusters, supercluster } = useSupercluster({
    points: points,
    bounds: bounds,
    zoom: zoom,
    options: { radius: 75, maxZoom: 17 },
  });

  return (
    <>
      {clusters?.map((cluster) => {
        // every cluster point has coordinates
        const [longitude, latitude] = cluster.geometry.coordinates;
        // the point may be either a cluster or a crime point
        const { cluster: isCluster, point_count: pointCount } = cluster.properties;

        // we have a cluster to render
        if (isCluster) {
          return (
            <Marker
              key={`clusters-${cluster.id}`}
              position={[latitude, longitude]}
              icon={fetchIcon(
                pointCount,
                10 + (pointCount / points.length) * 40
              )}
              eventHandlers={{
                click: () => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    maxZoom
                  );
                  map.setView([latitude, longitude], expansionZoom, {
                    animate: true,
                  });
                },
              }}
            />
          );
        }
        // we have a single point (crime) to render
        return (
          <div key={`crimes-${cluster.properties.siteId}`}>
            <Marker
              position={[latitude, longitude]}
              icon={cuffs}

            >
              <Popup spacing={2} sx={{ minHeight: '10vh', maxWidth: '100%', my: 'auto', mx: 'auto', p: 2 }}>
                {cluster?.properties?.name} <br />
                Koordinat : {latitude},{longitude}<br />
                Jalan : {cluster?.properties?.nama_jalan} <br />
                ID Splitter : {cluster?.properties?.splitter_id} <br />
                Koordinat Splitter : {cluster?.properties?.spliter_distribusi_koordinat} <br />
              </Popup>
            </Marker>
          </div>
        );
      })}
    </>
  );
}

export default ShowHomepass;