import { Routes, Route } from "react-router-dom";
import SiteIndex from './pages/sites';
import PageNotFound from './pages/404';
import PageServerError from './pages/500';
import DeviceIndex from './pages/devices';
import CreateDevice from './pages/devices/create';
import EditDevice from './pages/devices/edit';
import SideBarNav from './components/SideBar';
import Home from './pages/home'
import LoginPage from './pages/auth/login'
import { PrivateAuth, RouteNoAuth } from "./middlewares/auth";
import AuthTopNav from "./components/AppBarAuth";
import EditSite from "./pages/sites/edit";
import CreateSite from "./pages/sites/create";
import LinkIndex from "./pages/links";
import CreateLink from "./pages/links/create";
import EditLink from "./pages/links/edit";
import CustomerIndex from "./pages/customers";
import CreateCustomer from "./pages/customers/create";
import EditCustomer from "./pages/customers/edit";
import RegisterPage from "./pages/auth/register";
import VerifyUser from "./pages/auth/verifyUser";
import ResendVerifyLink from "./pages/auth/resendVerifyLink";
import ForgetPasswordPage from "./pages/auth/forgetPassword";
import ResetPasswordPage from "./pages/auth/resetPassword";
import WalletIndex from "./pages/wallet";
import CreateWallet from "./pages/wallet/create";
import EditWallet from "./pages/wallet/edit";
import LogBackup from "./pages/backup/log";
import IndexBackup from "./pages/backup";
import YearBackup from "./pages/backup/year";
import MonthBackup from "./pages/backup/month";
import DateBackup from "./pages/backup/date";
import SiteBackup from "./pages/backup/site";
import DeviceBackup from "./pages/backup/device";
import FrekuensiBts from "./pages/bts/frekuensi";
import IndexHomepass from "./pages/homepass";
import CoverageHomepass from "./pages/homepass/coverage";

const RouteApp = (props) => {
    return (
        <>
            <Routes>
                <Route path="/" element={<PrivateAuth><SideBarNav topmenu={props.topmenu} bottommenu={props.bottommenu} /></PrivateAuth>}>
                    <Route index element={<Home />} />
                    <Route path="sites" element={<SiteIndex />} />
                    <Route path="sites/edit/:siteId" element={<EditSite />} />
                    <Route path="sites/tambah" element={<CreateSite />} />
                    <Route path="devices" element={<DeviceIndex />} />
                    <Route path="devices/tambah" element={<CreateDevice />} />
                    <Route path="devices/edit/:deviceId" element={<EditDevice />} />
                    <Route path="links" element={<LinkIndex />} />
                    <Route path="links/tambah" element={<CreateLink />} />
                    <Route path="links/edit/:linkId" element={<EditLink />} />
                    <Route path="clients" element={<CustomerIndex />} />
                    <Route path="clients/tambah" element={<CreateCustomer />} />
                    <Route path="clients/edit/:clientId" element={<EditCustomer />} />
                    <Route path="clients/detail/:clientId" element={<EditCustomer />} />
                    <Route path="wallet" element={<WalletIndex />} />                    
                    <Route path="wallet/tambah" element={<CreateWallet />} />
                    <Route path="wallet/edit/:walletId" element={<EditWallet />} />                    
                    <Route path="import-homepass" element={<IndexHomepass />} />
                    <Route path="map-fiber-optik" element={<CoverageHomepass />} />
                    <Route path="backup-log" element={<LogBackup />} />
                    <Route path="backup/" element={<IndexBackup />} />
                    <Route path="backup/:year" element={<YearBackup />} />
                    <Route path="backup/:year/:month/" element={<MonthBackup />} />
                    <Route path="backup/:year/:month/:date" element={<DateBackup />} />
                    <Route path="backup/:year/:month/:date/:site" element={<SiteBackup />} />
                    <Route path="backup/:year/:month/:date/:site/:device" element={<DeviceBackup />} />
                    <Route path="backup/:year/:month/:date/:site/:device/:filename" element={<IndexBackup />} />
                    <Route path="bts/:siteId/frekuensi" element={<FrekuensiBts />} />
                    <Route path="resend-verify" element={<ResendVerifyLink />} />
                    <Route path="error" element={<PageServerError />} />
                    <Route path="*" element={<PageNotFound />} />
                </Route>
                <Route path="/" element={<RouteNoAuth><AuthTopNav /></RouteNoAuth>}>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/register" element={<RegisterPage />} />
                    <Route path="/forget-password/" element={<ForgetPasswordPage />} />
                    <Route path="/reset-password/:resetToken" element={<ResetPasswordPage />} />
                </Route>
                <Route path="/verify-email/:jwtToken" element={<VerifyUser />} />
            </Routes>
        </>
    )
}

export default RouteApp