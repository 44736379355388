import React, { useCallback, useEffect, useState } from "react";
import L from "leaflet";
import "./ShowCrimes.css";
import useSupercluster from "use-supercluster";
import { Marker, useMap, Polyline, Popup } from "react-leaflet";
import haversineService from "./services/haversine.service";

const icons = {};
const fetchIcon = (count, size) => {
  if (!icons[count]) {
    icons[count] = L.divIcon({
      html: `<div class="cluster-marker" style="width: ${size}px; height: ${size}px;">
        ${count}
      </div>`,
    });
  }
  return icons[count];
};

const cuffs = new L.Icon({
  iconUrl: "/location.png",
  iconSize: [25, 25],
});

function ShowCrimes({ data }) {
  const maxZoom = 22;
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(12);
  const map = useMap();

  // get map bounds
  function updateMap() {
    const b = map.getBounds();
    setBounds([
      b.getSouthWest().lng,
      b.getSouthWest().lat,
      b.getNorthEast().lng,
      b.getNorthEast().lat,
    ]);
    setZoom(map.getZoom());
  }

  const onMove = useCallback(() => {
    updateMap();
  }, [map]);

  React.useEffect(() => {
    updateMap();
  }, [map]);

  useEffect(() => {
    map.on("move", onMove);
    return () => {
      map.off("move", onMove);
    };
  }, [map, onMove]);

  const points = data?.payload?.map((e) => {
    const lat1 = parseFloat(e.host_device?.site?.latitude)
    const long1 = parseFloat(e.host_device?.site?.longitude)
    const lat2 = parseFloat(e.gateway_device?.site?.latitude)
    const long2 = parseFloat(e.gateway_device?.site?.longitude)
    return {
      type: "Feature",
      properties: {
        cluster: false,
        crimeId: e.id,
        name: e.name,
        site: e.gateway_device?.site?.name,
        jarak: haversineService.getDistance(lat1, long1, lat2, long2),
        bearing: haversineService.getBearing(lat1, long1, lat2, long2)
      },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(e.host_device?.site?.longitude),
          parseFloat(e.host_device?.site?.latitude),
          parseFloat(e.gateway_device?.site?.latitude),
          parseFloat(e.gateway_device?.site?.longitude),
        ],
      },
    }
  });

  const { clusters, supercluster } = useSupercluster({
    points: points,
    bounds: bounds,
    zoom: zoom,
    options: { radius: 75, maxZoom: 12 },
  });

  return (
    <>      
      {clusters?.map((cluster) => {
        // every cluster point has coordinates
        const [longitude, latitude, siteLatitude, siteLongitude] = cluster.geometry.coordinates;

        // the point may be either a cluster or a crime point
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties;

        // we have a cluster to render
        if (isCluster) {          
          return (
            <Marker
              key={`clientcluster-${cluster.id}`}
              position={[latitude, longitude]}
              icon={fetchIcon(
                pointCount,
                10 + (pointCount / points.length) * 40
              )}
              eventHandlers={{
                click: () => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    maxZoom
                  );
                  map.setView([latitude, longitude], expansionZoom, {
                    animate: true,
                  });
                },
              }}
            />
          );
        }

        // we have a single point (crime) to render
        return (
          <div key={`crime-${cluster.properties?.crimeId}`}>
            <Polyline positions={[
              [latitude, longitude],
              [siteLatitude, siteLongitude],
            ]} color={'red'}
            />
            <Marker
              position={[latitude, longitude]}
              icon={cuffs}>
              <Popup>
                Nama : {cluster?.properties?.name} <br />
                Terhubung ke : {cluster?.properties?.site} <br />
                Jarak : {cluster?.properties?.jarak} km<br />
                Bearing : {cluster?.properties?.bearing} &deg; <br />
              </Popup>
            </Marker>
          </div>
        );
      })}
    </>
  );
}

export default ShowCrimes;