import * as React from 'react'
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux";
import { bindActionCreators } from 'redux'
import { actionCreators } from "../../state/index"
import BackupDataService from '../../services/backup.service'
import Grid from '@mui/material/Grid';
import FolderButton from '../../components/folderButton';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { Box, Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BackdropApp from '../../components/BackdropApp';

const YearBackup = () => {
    let { year } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dir = useSelector((state) => state.backup);
    const { setBackupFile } = bindActionCreators(actionCreators, dispatch);
    const [loaded, setloaded] = React.useState(false)

    async function getData(req) {
        let data = await BackupDataService.findFile(req)
        
        setBackupFile(data.payload)
        setloaded(true)
    }

    React.useEffect(() => {
        if (!loaded) {
            getData({
                year: year
            })
        }

    }, [loaded])

    function handleDirectoryClick(e) {
        navigate(e)
    }
    const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
        "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    
    if (!loaded) return <BackdropApp />
    return (
        <>
            <Stack
                m={0}
                direction={{ xs: 'row', sm: 'row' }}
                spacing={{ xs: 1, sm: 1, md: 1 }}
                alignItems="center"
            // justifyContent="space-between"
            >
                <IconButton onClick={() => navigate('/backup')} color="primary" aria-label="add to shopping cart">
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant='h6'>Browse backup : Pilih Bulan</Typography>
            </Stack>
            <Paper sx={{ minHeight: '70vh', maxWidth: '100%', my: 1, mx: 'auto', p: 2 }}>
                <Grid container spacing={2}>
                    {
                        dir?.map((e) => {
                            return <Grid key={e} item md={2} xs={12}>
                                <FolderButton name={monthNames[Number(e) - 1]} event={() => handleDirectoryClick(e)} />
                            </Grid>
                        })
                    }
                </Grid>
            </Paper>
        </>
    )
}

export default YearBackup