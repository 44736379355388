import * as React from 'react';
import { useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react'
import SiteTableAll from "../../components/GeneralTable";
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import Backdrop from '@mui/material/Backdrop';
import { Button, IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable';
import { Helmet } from "react-helmet-async";
import homepassService from '../../services/homepass.service';
import Checkbox from '@mui/material/Checkbox';

const Input = styled('input')({
    display: 'none',
});
const columns = [
    { id: 'number', label: 'No.', minWidth: 20 },
    { id: 'id', label: 'ID', minWidth: 20 },
    { id: 'homepass_id', label: 'Homepass ID', minWidth: 170 },
    {
        id: 'homepassed_koordinat',
        label: 'Lokasi',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'nama_jalan',
        label: 'Jalan',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'splitter_id',
        label: 'ID Splitter',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'action',
        label: '',
        minWidth: 170,
        align: 'right',
    }
];
function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const IndexHomepass = () => {
    const [loaded, setloaded] = useState(false)
    const [homepass, sethomepass] = useState([])
    const [search, setsearch] = useState("")
    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    function createData(data) {
        let array = [];
        for (let i = 0; i < data.length; i++) {
            const e = data[i];
            const action = (
                <>
                    <Checkbox value={e.id} onChange={handleCheckboxDelete} />
                </>
            )
            array.push({
                number: i + 1,
                id: e.id,
                homepass_id: e.homepass_id,
                homepassed_koordinat: e.homepassed_koordinat,
                nama_jalan: e.nama_jalan,
                splitter_id: e.splitter_id,
                action: action
            })

        }
        return array
    }
    const [hapus, sethapus] = useState([])

    async function handleCheckboxDelete(e) {
        if (e.target.checked) {
            let newArr = hapus
            newArr.push(e.target.value)
            sethapus(newArr)                      
        } else {
            let newArr = hapus
            const index = newArr.indexOf(e.target.value);
            if (index > -1) {
                newArr.splice(index, 1);
            }
            sethapus(newArr)            
        }
    }

    async function getData() {
        const x = await homepassService.getAll()

        if (x.success === false) {
            enqueueSnackbar(x.errors?.message, {
                variant: 'error',
            })
            if (x.errors?.message === "Email unverified") {
                navigate("/resend-verify")
            } else {
                navigate("/error")
            }
        } else {
            const array = createData(x.payload)
            sethomepass(array)
            setloaded(true)
        }
    }
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        getData()
        console.log(hapus)
    }, [hapus,sethapus])

    const handleSearch = (event) => {
        setsearch(event.target.value)
    }
    const submitSearch = async () => {
        let res = await homepassService.getAll()
        res = createData(res.payload)
        let filtered = res.filter((e) => {
            let x = Object.values(e?.homepass_id)?.join([""]).toLowerCase().includes(search.toLowerCase())
                || Object.values(e?.nama_jalan)?.join([""]).toLowerCase().includes(search.toLowerCase())

            return x;
        })

        sethomepass([...filtered])
    }

    const handleHapus = async () => { 
        console.log(hapus)       
        const response = await homepassService.delete(hapus)

        if (response.success) {
            enqueueSnackbar(response.message, {
                variant: 'success',
            });
            getData()
            setOpen(false)
        } else {
            setOpen(false)
            console.log(response)
            if (Array.isArray(response.validationErrors)) {
                response.validationErrors.map((e) => {
                    enqueueSnackbar(e.msg, {
                        variant: 'error',
                    });
                })
            } else {
                enqueueSnackbar(response.errors?.message, {
                    variant: 'error',
                });
            }
        }
    }

    const [file, setfile] = useState({
        excel: ''
    })
    function handleupload(event) {
        setfile({
            excel: event.target.files[0]
        })
    }
    const handleButton = async () => {
        setloaded(false)
        const formData = new FormData();
        formData.append(
            "excel",
            file.excel
        );
        const api = await homepassService.create(formData)
        if (api.success) {
            setloaded(true)
            enqueueSnackbar(api.message, {
                variant: 'success',
            });
            setfile({
                excel: "",
            })
            getData()
        } else {
            setloaded(true)
            if (Array.isArray(api.validationErrors)) {
                api.validationErrors.map((e, i) => {
                    return enqueueSnackbar(e.msg, {
                        variant: 'error',
                    });
                })
            } else {
                enqueueSnackbar(api.errors, {
                    variant: 'error',
                });
            }
        }
    }
    if (!loaded) {
        return (
            <>
                <Backdrop
                    sx={{ color: '#1976d2', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={!loaded}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        )
    } else {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Helper - Homepass</title>
                </Helmet>
                <h2>Homepass Table</h2>
                <Stack
                    m={2}
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    alignItems="flex-start"
                    justifyContent="space-between"
                >
                    <Paper>
                        <InputBase
                            onChange={handleSearch}
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Cari pada tabel"
                            inputProps={{ 'aria-label': 'Cari pada tabel' }}
                        />
                        <IconButton onClick={submitSearch} type="submit" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                    <Stack
                        m={2}
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                        alignItems="flex-start"
                        justifyContent="space-between"
                    >
                        <label htmlFor="contained-button-file">
                            <Input onChange={handleupload} accept="application/*" id="contained-button-file" type="file" />
                            <Button variant="contained" color='success' component="span">
                                Pilih file excel
                            </Button>
                        </label>
                        {
                            file.excel ? file.excel.name : ''
                        }
                        <Button onClick={handleButton} variant="contained">Tambah +</Button>
                        <Button onClick={handleClickOpen} color="error" variant="contained">Hapus Selected</Button>
                    </Stack>
                </Stack>
                <SiteTableAll data={homepass} columns={columns} />
                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Hapus Homepass
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            apakah anda yakin?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleHapus}>Ya, Hapus</Button>
                        <Button autoFocus onClick={handleClose}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

export default IndexHomepass