import FormDevice from "../../components/devices/form"

const CreateDevice = () => {
    return (
        <>
            <FormDevice />
        </>
    )
}

export default CreateDevice