import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MainTopNav from './AppBar';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../state/index";
import { Outlet, Link } from "react-router-dom";
import Grid from '@mui/material/Grid';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,        
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function SideBarNav(props) {
    const theme = useTheme();
    const sidebarOpen = useSelector((state) => state.menu);

    const dispatch = useDispatch();
    const { setSidebarOpen } = bindActionCreators(actionCreators, dispatch);
    const handleDrawerClose = () => {
        // setOpen(false);
        setSidebarOpen(!sidebarOpen)
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                {/* ---------APPBAR ----------------------- */}
                <MainTopNav />
                {/* ---------APPBAR ----------------------- */}
                <Drawer variant="permanent" open={sidebarOpen}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                        {props.topmenu.map((text, index) => (
                            <Link key={index} to={text.link ? text.link : "/"} style={{ textDecoration: 'none' }}>
                                <ListItem button key={text.name}>
                                    <ListItemIcon>
                                        {text.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={text.name} />
                                </ListItem>
                            </Link>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {props.bottommenu.map((text, index) => (
                            <Link key={index} to={text.link ? text.link : "/"} style={{ textDecoration: 'none' }}>
                                <ListItem button key={text.name}>
                                    <ListItemIcon>
                                        {text.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={text.name} />
                                </ListItem>
                            </Link>
                        ))}
                    </List>
                    <List>

                    </List>
                </Drawer>
                <Box sx={{
                    flexGrow: 1,
                    justifyContent: 'flex-start',
                    p: 3,

                }} maxWidth={`calc(100% - ${theme.spacing(9)} + 1px)`}>
                    <DrawerHeader />

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Outlet />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
}
