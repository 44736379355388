import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const api = axios.create({
    baseURL: "https://auth.pwkt.citra.net.id/api",
});

// Add request interceptor
api.interceptors.request.use(
    async (config) => {
        let token = "initial token"
        if (typeof window !== 'undefined') {
            token = localStorage.getItem('access_token');
        }

        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        config.headers['Content-Type'] = 'application/json';
        config.headers['Accept'] = 'application/json';
        config.headers['Access-Control-Allow-Origin'] = '*';
        return config;
    },
    error => {        
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        if (error.toString() === "Error: Network Error") return {
            success: false,
            errors: {
                message: error.toString()
            }
        }
        return error.response.data
    });
export default api