import {
    useParams,
} from "react-router-dom";
import { useState } from "react";
import { useEffect } from 'react';
import FormDevice from "../../components/devices/form"
import PageNotFound from "../../pages/404"
import deviceService from "../../services/device.service";
import { useSnackbar } from 'notistack';
import BackdropApp from "../../components/BackdropApp";

const EditDevice = () => {
    let { deviceId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [edited, setedited] = useState("")
    const [loaded, setloaded] = useState(false)

    const ambilDeviceEdited = async () => {
        const data = await deviceService.get(deviceId)
        if (!data.success) enqueueSnackbar(data.message, {
            variant: 'error',
        });
        setedited(data.payload)
        setloaded(true)
    }

    useEffect(() => {
        ambilDeviceEdited()
    }, [])


    if (edited) {
        return (
            <>
                <FormDevice devices={edited} />
            </>
        )
    } else {
        if (loaded) {
            return (
                <>
                    <PageNotFound />
                </>
            )
        } else {
            return (
                <>                                
                    <BackdropApp loaded={loaded} />
                </>
            )

        }
    }
}

export default EditDevice