import * as React from 'react'
import { useParams } from 'react-router-dom'
import deviceService from '../../services/device.service'
import MikrotikService from '../../services/mikrotik.service'
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AccordionApp from '../../components/AccordionWireless';
import BackdropApp from '../../components/BackdropApp';


const FrekuensiBts = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { siteId } = useParams();

    const [conf, setconf] = React.useState([]);
    const [loaded, setloaded] = React.useState(false);

    async function getDevices(siteId) {
        const data = await deviceService.getBySite(siteId)
        if (data.success === false) {
            enqueueSnackbar(data.errors?.message, {
                variant: 'error',
            })
            if (data.errors?.message === "Email unverified") {
                navigate("/resend-verify")
            } else {
                navigate("/error")
            }
        } else {
            let arr = data?.payload

            arr.forEach(async (e, i) => {
                if (e.device_category === 2 || e.device_category === 5) {                    
                    const res = await MikrotikService.getconf(e.ip)                    
                    if (!res.success) {
                        enqueueSnackbar(res?.errors?.message, {
                            variant: 'error',
                        })
                        enqueueSnackbar(`Tidak dapat terhubung : ${res?.errors?.name}`, {
                            variant: 'warning',
                        })
                    }
                    setconf(conf => [
                        ...conf,
                        res.payload
                    ]
                    )
                }
            });

            setloaded(true)
        }
    }

    React.useEffect(() => {
        if (!loaded) getDevices(siteId)
        // console.log(conf)
    }, [conf])

    if (!loaded) {
        return <BackdropApp />
    } else {
        return (
            <>
                {
                    conf.filter(function (res) {
                        let arr = res?.wireless
                        if (!arr?.length) {
                            return false;
                        }
                        return true;
                    }).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                        .map(function (device) {
                            device?.wireless?.sort((a, b) => (a.frequency > b.frequency) ? 1 : ((b.frequency > a.frequency) ? -1 : 0))
                            return device?.wireless?.map((e, i) => {
                                return <AccordionApp
                                    data
                                    key={e['mac-address']}
                                    name={device.name}
                                    wlan={e}
                                />
                            })
                        })
                }
            </>
        )
    }
}

export default FrekuensiBts