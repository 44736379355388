import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import sitesService from '../../services/sites.service';
import sitesCatService from '../../services/site-category.service';
import deviceService from '../../services/device.service';
import { useSnackbar } from 'notistack';
import { useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import ComputerIcon from '@mui/icons-material/Computer';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import {
    useLocation,
    useNavigate,
} from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import RouterIcon from '@mui/icons-material/Router';

export default function DeviceForm(props) {
    const [sitecat, setsitecat] = useState([{}])
    const [sites, setsites] = useState([{}])
    const [devicecat, setdevicecat] = useState([{}])
    const [InputDevice, setInputDevice] = useState({})

    let navigate = useNavigate();
    let location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const devices = props.devices

    let from = location.state?.from?.pathname || "/devices";

    useEffect(() => {
        ambilSiteCategory()
        ambilSites()
        ambilDeviceCategory()

        if (devices !== undefined) {

            return setInputDevice({
                id: devices?.id,
                name: devices?.name,
                site_id: devices?.site_id,
                ip: devices?.ip,
                device_category: devices?.device_category,
                is_backup: devices?.is_backup ? 'backup' : 'nobackup'
            })
        }
    }, [devices])

    const handleChange = (e) => {
        if (e.target.type == 'checkbox') {
            setInputDevice({
                ...InputDevice,
                [e.target.name]: e.target.checked ? 'backup' : 'nobackup'
            })
        } else {
            setInputDevice({
                ...InputDevice,
                [e.target.name]: e.target.value
            })
        }
    }

    const ambilSiteCategory = async () => {
        const data = await sitesCatService.getAll()
        if (data.success === false) {
            enqueueSnackbar(data.errors?.message, {
                variant: 'error',
            })
        } else {
            setsitecat(data.payload)
        }
    }
    const ambilSites = async () => {
        const data = await sitesService.getAll()
        if (data.success === false) {
            enqueueSnackbar(data.errors?.message, {
                variant: 'error',
            })
        } else {
            setsites(data.payload)
        }
    }
    const ambilDeviceCategory = async () => {
        const data = await deviceService.getCategory()
        if (data.success === false) {
            enqueueSnackbar(data.errors?.message, {
                variant: 'error',
            })
        } else {
            setdevicecat(data.payload)
        }
    }

    const handleSubmit = async () => {
        setInputDevice({
            ...InputDevice,
            is_backup: 'nobackup'
        })        
        if (devices) {
            const api = await deviceService.update(InputDevice, devices.id)

            if (api.success) {
                enqueueSnackbar(api.message, {
                    variant: 'success',
                });

                navigate(from, { replace: true });
            } else {
                if (Array.isArray(api.validationErrors)) {
                    api.validationErrors.map((e) => {
                        enqueueSnackbar(e.msg, {
                            variant: 'error',
                        });
                    })
                } else {
                    enqueueSnackbar(api.errors?.message, {
                        variant: 'error',
                    });
                }
            }
        } else {
            const api = await deviceService.create(InputDevice)

            if (api.success) {
                enqueueSnackbar(api.message, {
                    variant: 'success',
                });

                navigate(from, { replace: true });
            } else {
                if (Array.isArray(api.validationErrors)) {
                    api.validationErrors.map((e) => {
                        enqueueSnackbar(e.msg, {
                            variant: 'error',
                        });
                    })
                } else {
                    enqueueSnackbar(api.errors?.message, {
                        variant: 'error',
                    });
                }
            }
        }
    }

    return (
        <Grid container spacing={5}>
            <Grid item md={3}>
            </Grid>
            <Grid item md={6}>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="nama">Nama Perangkat</InputLabel>
                            <Input
                                onChange={handleChange}
                                name="name"
                                label="Nama perangkat"
                                id="nama"
                                value={InputDevice?.name ? InputDevice?.name : ""}
                                placeholder="Nama Perangkat"
                                size="small"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton disabled
                                        >
                                            {<ComputerIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel id="sites">Pilih Lokasi</InputLabel>
                            <Select
                                labelId="sites"
                                id="selectSite"
                                value={InputDevice?.site_id ? InputDevice?.site_id : 0}
                                label="Pilih Lokasi"
                                onChange={handleChange}
                                name="site_id"
                            >
                                <MenuItem selected disabled value={0}>- Pilih lokasi -</MenuItem>
                                {
                                    sites?.map((e, i) => {
                                        return <MenuItem key={i} value={e.id}>{e.name?.toUpperCase()}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="latitude">IP Address</InputLabel>
                            <Input
                                onChange={handleChange}
                                value={InputDevice?.ip ? InputDevice?.ip : ""}
                                name="ip"
                                label="IP Address"
                                id="ip"
                                placeholder="IP Addres v4"
                                variant="filled"
                                size="small"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton disabled
                                        >
                                            {<RouterIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel id="deviceCategory">Pilih Kategori Perangkat</InputLabel>
                            <Select
                                labelId="deviceCategory"
                                id="selectDeviceCategory"
                                value={InputDevice?.device_category ? InputDevice?.device_category : 0}
                                label="Pilih Kategori"
                                onChange={handleChange}
                                name="device_category"
                            >
                                <MenuItem selected disabled value={0}>- Pilih Kategori -</MenuItem>
                                {
                                    devicecat?.map((e, i) => {
                                        return <MenuItem key={i} value={e.id}>{e.name?.toUpperCase()}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={12}>
                        <FormControl fullWidth variant="standard">
                            <FormControlLabel control={<Switch checked={InputDevice?.is_backup === "backup" ? true : false} onChange={handleChange} name="is_backup" />} label="Apakah perangkat ini perlu untuk masuk sistem backup?" />
                        </FormControl>
                    </Grid>
                    <Grid item md={12}>
                        <Button fullWidth variant="contained" onClick={handleSubmit} aria-label="edit" color="primary">
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={3}>
            </Grid>
        </Grid>
    );
}