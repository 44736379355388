import React, { useCallback, useEffect, useState } from "react";
import L from "leaflet";
import "./ShowCrimes.css";
import useSupercluster from "use-supercluster";
import { Marker, useMap, Popup } from "react-leaflet";
import { Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const icons = {};
const fetchIcon = (count, size) => {
  if (!icons[count]) {
    icons[count] = L.divIcon({
      html: `<div class="cluster-site-marker" style="width: ${size}px; height: ${size}px;">
        ${count}
      </div>`,
    });
  }
  return icons[count];
};

const cuffs = new L.Icon({
  iconUrl: "/bts.png",
  iconSize: [35, 35],
});

function ShowSites({ data }) {
  const navigate = useNavigate();
  const maxZoom = 22;
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(12);
  const map = useMap();

  // get map bounds
  function updateMap() {
    const b = map.getBounds();
    setBounds([
      b.getSouthWest().lng,
      b.getSouthWest().lat,
      b.getNorthEast().lng,
      b.getNorthEast().lat,
    ]);

    setZoom(map.getZoom());
  }

  const onMove = useCallback(() => {    
    updateMap()    
  }, [map]);

  React.useEffect(() => {
    updateMap();
  }, [map]);

  useEffect(() => {
    map.on("move", onMove);
    return () => {
      map.off("move", onMove);
    };
  }, [map, onMove]);

  const points = data?.map((e) => ({
    type: "Feature",
    properties: {
      cluster: false,
      siteId: e.id,
      name: e.name,
      countClient: e.countClient,
      countDevice: e.countDevice,
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(e.longitude),
        parseFloat(e.latitude),
      ],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points: points,
    bounds: bounds,
    zoom: zoom,
    options: { radius: 75, maxZoom: 17 },
  });

  return (
    <>            
      {clusters?.map((cluster) => {
        // every cluster point has coordinates
        const [longitude, latitude] = cluster.geometry.coordinates;
        // the point may be either a cluster or a crime point
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties;

        // we have a cluster to render
        if (isCluster) {          
          return (
            <Marker
              key={`clusters-${cluster.id}`}
              position={[latitude, longitude]}
              icon={fetchIcon(
                pointCount,
                10 + (pointCount / points.length) * 40
              )}
              eventHandlers={{
                click: () => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    maxZoom
                  );
                  map.setView([latitude, longitude], expansionZoom, {
                    animate: true,
                  });
                },
              }}
            />
          );
        }
        
        // we have a single point (crime) to render
        return (
          <div key={`crimes-${cluster.properties.siteId}`}>
            <Marker
              position={[latitude, longitude]}
              icon={cuffs}

            >
              <Popup spacing={2} sx={{ minHeight: '10vh', maxWidth: '100%', my: 'auto', mx: 'auto', p: 2 }}>
                {cluster?.properties?.name} <br />
                Jumlah client : {cluster?.properties?.countClient} <br />
                Jumlah perangkat : {cluster?.properties?.countDevice} <br /><br />
                <Button onClick={() => navigate(`bts/${cluster?.properties?.siteId}/frekuensi`)} sx={{ width: "100%" }} variant="contained" size="small" color="warning" title="Lihat frekuensi yang digunakan">
                  List Frekuensi
                </Button><br />
                <Button onClick={() => navigate(`ap-controller/${cluster?.properties?.siteId}`)} sx={{ width: "100%" }} variant="contained" size="small" color="success" title="Manage AP pada lokasi ini (coming soon)">
                  AP Controller
                </Button><br />
                <Button onClick={() => navigate(`bts/${cluster?.properties?.siteId}`)} sx={{ width: "100%" }} variant="contained" size="small" title="Lihat semua perangkat yang ada di lokasi">
                  Detail
                </Button>
              </Popup>
            </Marker>
          </div>
        );
      })}
    </>
  );
}

export default ShowSites;