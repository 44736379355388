import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import BackdropApp from '../../components/BackdropApp';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import AuthService from '../../services/auth.service'
import EmailIcon from '@mui/icons-material/Email';
import { Helmet } from "react-helmet-async";

const ForgetPasswordPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [loaded, setloaded] = React.useState(true)
    const [sent, setsent] = React.useState(false)
    const [values, setValues] = React.useState({
        email: ""
    })

    const handleChange = (event) => {
        setValues({ [event.target.name]: event.target.value });
    };

    const handleSubmit = async () => {
        setloaded(false)
        const auth = await AuthService.sendResetPassword(values)
        if (auth.success === false) {
            setloaded(true)
            if (Array.isArray(auth.validationErrors)) {
                auth.validationErrors.map((e, i) => {
                    enqueueSnackbar(e.msg, {
                        variant: 'error',
                    });
                })
            } else {
                enqueueSnackbar(auth.errors?.message, {
                    variant: 'error',
                });
            }
        } else {
            setloaded(true)
            setsent(true)
            enqueueSnackbar("email sent to : " + auth.payload, {
                variant: 'success',
            })
        }

    }

    const sentMail = <Stack
        component="form"
        sx={{
            width: '80%',
            padding: 2,
        }}
        alignContent="center"
        alignItems="center"
        spacing={2}
        noValidate
        autoComplete="off"
    >
        <br />
        <br />
        <Typography align="center" variant="h5">Reset link sudah dikirim, silahkan cek email anda</Typography>
    </Stack>

    React.useEffect(() => {
        if (!loaded) {
            return <BackdropApp />
        }
        if (sent) {
            return sentMail
        }
    }, [])

    if (sent) return sentMail

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Helper - Forget Passowrd</title>
            </Helmet>
            <Stack
                onSubmit={handleSubmit}
                component="form"
                sx={{
                    width: '80%',
                    padding: 2,
                }}
                alignContent="center"
                alignItems="center"
                spacing={2}
                noValidate
                autoComplete="off"
            >
                <br />
                <br />
                <Typography align="center" >
                    Lupa password Anda? Masukkan email anda dan cek email untuk mendapatkan link untuk reset password
                </Typography><br />
                <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">email</InputLabel>
                    <Input
                        id="email"
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton disabled
                                >
                                    {<EmailIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl fullWidth variant="standard">
                    <Button onClick={handleSubmit} variant="contained">SEND RESET PASSWORD</Button>
                </FormControl>
                <Typography>
                    Atau mau <Link to="/login">login</Link> ?
                </Typography>
            </Stack>
            {!loaded ? <BackdropApp /> : null}
        </>
    )
}

export default ForgetPasswordPage