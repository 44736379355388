import http from "../common/http-common";

class DeviceDataService {
  getAll() {
    const data = http.get("/device/read/")

    return data
  }
  getBySite(siteId) {
    const data = http.get("/device/" + siteId + "/read/")

    return data
  }
  getCategory() {
    const data = http.get("/device-category/read")

    return data
  }

  get(id) {
    const data = http.get(`/device/read/${id}`)

    return data
  }

  create(form) {
    const data = http.post("/device/create", form)

    return data
  }

  update(form, id) {
    const data = http.put(`/device/${id}/update`, form)

    return data
  }

  delete(id) {
    const data = http.delete(`/device/${id}/delete`)

    return data
  }

}

export default new DeviceDataService();