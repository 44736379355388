import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Stack } from '@mui/material';
import Chip from '@mui/material/Chip';

const AccordionApp = (props) => {
    const { name, host, wlan } = props
    return (
        <Accordion style={{minHeight:"11vh"}} disabled={wlan.disabled === 'true' ? true : false}>
            <AccordionSummary
                expandIcon={
                    <ExpandMoreIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Stack
                    m={2}
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                    alignItems="flex-start"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography>{name} | {wlan.name}</Typography>
                    <Typography>{wlan.frequency}</Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Stack
                    m={2}
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                    alignItems="flex-start"
                    // justifyContent="space-between"                    
                >
                    <Chip color='primary' label={`Mode : ${wlan.mode}`} />
                    <Chip color='success' label={`SSID : ${wlan.ssid}`} />
                    <Chip color='success' label={`Mac Address : ${wlan['mac-address']}`} />
                    <Chip color='warning' label={`Channel width : ${wlan['channel-width']}`} />
                    <Chip color='success' label={`Protocol : ${wlan['wireless-protocol']}`} />
                    <Chip color='success' label={`Chipset : ${wlan['interface-type']}`} />                    
                </Stack>
            </AccordionDetails>
        </Accordion>
    )
}

export default AccordionApp