import React from "react";
import { Helmet } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import Map from "../Map";
const queryClient = new QueryClient();

export default function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Helper - Dashboard</title>
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <Map />
      </QueryClientProvider>
    </>
  );
}