import http from "../common/http-common";

class LinkService {
  getAll() {
    const data = http.get("/link/read/")

    return data
  }

  get(id) {    
    const data = http.get(`/link/read/${id}`)

    return data
  }

  create(form) {    
    const data = http.post("/link/create", form)

    return data
  }

  update(form, id) {    
    const data = http.put(`/link/${id}/update`, form)

    return data
  }

  delete(id) {    
    const data = http.delete(`/link/${id}/delete`)

    return data
  }

}

export default new LinkService();