import * as React from 'react';
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux";
import { bindActionCreators } from 'redux'
import { actionCreators } from "../../state/index"
import LinkDataService from "../../services/link.service"
import { useEffect, useState } from 'react'
import SiteTableAll from "../../components/GeneralTable";
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import Backdrop from '@mui/material/Backdrop';
import { Button, IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from "react-router-dom";
import Draggable from 'react-draggable';
import { Helmet } from "react-helmet-async";

const columns = [
    { id: 'number', label: 'No.', minWidth: 20 },
    { id: 'id', label: 'ID', minWidth: 20 },
    { id: 'name', label: 'Name', minWidth: 170 },
    {
        id: 'gateway',
        label: 'Gateway',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'ipGateway',
        label: '',
        minWidth: 120,
        align: 'left',
    },
    {
        id: 'host',
        label: 'Host',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'ipHost',
        label: '',
        minWidth: 120,
        align: 'left',
    },
    {
        id: 'transmisi',
        label: 'Transmisi',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'action',
        label: '',
        align: 'right',
    }
];

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const LinkIndex = () => {
    const state = useSelector((state) => state.link);
    const [loaded, setloaded] = useState(false)
    const [search, setsearch] = useState("")
    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { setLinks, setLoggedIn } = bindActionCreators(actionCreators, dispatch);

    function createData(data) {
        let array = [];
        for (let i = 0; i < data.length; i++) {
            const e = data[i];

            const action = (
                <>
                    <Link to={`/links/edit/${e.id}`}>
                        <IconButton aria-label="edit" color="primary">
                            <SettingsIcon />
                        </IconButton>
                    </Link>
                    <IconButton onClick={() => handleClickOpen(e.id)} aria-label="delete" color="error">
                        <DeleteIcon />
                    </IconButton>
                </>
            )
            array.push({
                number: i + 1,
                id: e.id,
                name: e.name,
                gateway: e.gateway_device.name,
                ipGateway: e.gateway_device.ip,
                host: e.host_device.name,
                ipHost: e.host_device.ip,
                transmisi: e.is_wireless ? 'Wireless' : 'Wired',
                action: action
            })

        }
        return array
    }
    async function getData() {
        const x = await LinkDataService.getAll()

        if (x.success === false) {
            enqueueSnackbar(x.errors?.message, {
                variant: 'error',
            })
            if (x.errors?.message === "Email unverified") {
                navigate("/resend-verify")
            } else {
                navigate("/error")
            }
        } else {
            const array = createData(x.payload)
            setLinks(array)
            setloaded(true)
        }
    }
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = (e) => {
        setIdHapus(e)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        getData()
    }, [])

    const handleButton = () => {
        navigate('/links/tambah');
    }
    const handleSearch = (event) => {
        setsearch(event.target.value)
    }
    const submitSearch = async () => {
        let res = await LinkDataService.getAll()
        res = createData(res.payload)
        let filtered = res.filter((e) => {
            let x = Object.values(e.name).join([""]).toLowerCase().includes(search.toLowerCase())
            || Object.values(e.gateway).join([""]).toLowerCase().includes(search.toLowerCase())
            || Object.values(e.ipGateway).join([""]).toLowerCase().includes(search.toLowerCase())
            || Object.values(e.host).join([""]).toLowerCase().includes(search.toLowerCase())
            || Object.values(e.ipHost).join([""]).toLowerCase().includes(search.toLowerCase())
            || Object.values(e.transmisi).join([""]).toLowerCase().includes(search.toLowerCase())
            
            return x;
        })
        setLinks([...filtered])
    }

    const [IdHapus, setIdHapus] = React.useState(0)

    const handleHapus = async () => {
        const response = await LinkDataService.delete(IdHapus)

        if (response.success) {
            enqueueSnackbar(response.message, {
                variant: 'success',
            });
            let index = state.findIndex((x) => x.id === IdHapus);
            if (index > -1) {
                state.splice(index, 1);
            }
            setOpen(false)
        } else {
            enqueueSnackbar(response.errors?.message, {
                variant: 'error',
            })
            if (response.errors?.message === "Email unverified") {
                navigate("/resend-verify")
            } else {
                navigate("/error")
            }
        }
    }

    if (!loaded) {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Helper - Link</title>
                </Helmet>
                <Backdrop
                    sx={{ color: '#1976d2', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={!loaded}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        )
    } else {
        return (
            <>
                <h2>Links Table</h2>
                <Stack
                    m={2}
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    alignItems="flex-start"
                    justifyContent="space-between"
                >
                    <Paper>
                        <InputBase
                            onChange={handleSearch}
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Cari pada tabel"
                            inputProps={{ 'aria-label': 'Cari pada tabel' }}
                        />
                        <IconButton onClick={submitSearch} type="submit" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                    <Paper>
                        <Button onClick={handleButton} variant="contained">Tambah +</Button>
                    </Paper>
                </Stack>
                <SiteTableAll data={state} columns={columns} />
                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Hapus Site
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Semua yang berhubungan dengan site ini akan dihapus juga. apakah anda yakin?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleHapus}>Ya, Hapus</Button>
                        <Button autoFocus onClick={handleClose}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

export default LinkIndex