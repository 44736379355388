import AccountTreeIcon from '@mui/icons-material/AccountTree';
import HomeIcon from '@mui/icons-material/Home';
import RouterIcon from '@mui/icons-material/Router';
import LinkIcon from '@mui/icons-material/Link';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import RouteApp from './Routes'
import { SnackbarProvider } from 'notistack';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import UploadFileIcon from '@mui/icons-material/UploadFile';

function App() {
  const topmenu = [
    {
      name: 'Home',
      link: '/',
      icon: <HomeIcon />
    },
    {
      name: 'Fiber Optik',
      link: '/map-fiber-optik',
      icon: <AssuredWorkloadIcon />
    },
    {
      name: 'Client',
      link: '/clients',
      icon: <ConnectWithoutContactIcon />
    },
    {
      name: 'Wallet',
      link: '/wallet',
      icon: <AutoStoriesIcon />
    },
  ]

  const bottommenu = [
    {
      name: 'Sites',
      link: '/sites',
      icon: <AccountTreeIcon />
    },
    {
      name: 'Devices',
      link: '/devices',
      icon: <RouterIcon />
    },
    {
      name: 'Link',
      link: '/links',
      icon: <LinkIcon />
    },
    {
      name: 'Import Homepass',
      link: '/import-homepass',
      icon: <UploadFileIcon />
    },
    {
      name: 'Download Backup',
      link: '/backup',
      icon: <CloudDownloadIcon />
    },
    {
      name: 'Backup Log',
      link: '/backup-log',
      icon: <TextSnippetIcon />
    },
  ]

  return (
    <div>
      <SnackbarProvider maxSnack={3}>
        <RouteApp topmenu={topmenu} bottommenu={bottommenu} />
      </SnackbarProvider>
    </div>
  );
}

export default App;
