import SiteForm from "../../components/sites/form"

const CreateSite = () => {
    return (
        <>
            <SiteForm />
        </>
    )
}

export default CreateSite