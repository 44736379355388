import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import deviceService from '../../services/device.service';
import { useSnackbar } from 'notistack';
import { useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import {
    useLocation,
    useNavigate,
} from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import linkService from '../../services/link.service';
import LeakAddIcon from '@mui/icons-material/LeakAdd';

export default function LinkForm(props) {
    const [devices, setdevices] = useState([{}])
    const [gateway, setgateway] = useState([{}])
    const [host, sethost] = useState([{}])
    const [InputLink, setInputLink] = useState({})

    let navigate = useNavigate();
    let location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const links = props.links

    let from = location.state?.from?.pathname || "/links";

    useEffect(() => {
        ambilDevices()

        if (links !== undefined) {
            return setInputLink({
                id: links?.id,
                name: links?.name,
                gateway: links?.gateway,
                host: links?.host,
                is_wireless: links?.is_wireless ? 'wireless' : 'wired'
            })
        }
    }, [links])


    const handleChange = (e) => {
        if (e.target.type === 'checkbox' && e.target.name === 'is_wireless') {
            setInputLink({
                ...InputLink,
                [e.target.name]: e.target.checked ? 'wireless' : 'wired'
            })
        } else {
            setInputLink({
                ...InputLink,
                [e.target.name]: e.target.value
            })
        }
    }

    const ambilDevices = async () => {
        const data = await deviceService.getAll()

        let gatewayDevice = data.payload?.filter((e) => {
            let x = e.device_category !== 1 && e.device_category !== 6

            return x;
        })
        let hostDevice = data.payload?.filter((e) => {
            let x = e.device_category === 1 || e.device_category === 6

            return x;
        })
        setgateway(gatewayDevice)
        sethost(hostDevice)
        setdevices(data.payload)
    }

    const handleSubmit = async () => {
        setInputLink({
            ...InputLink,
        })

        if (links) {
            const api = await linkService.update(InputLink, links.id)

            if (api.success) {
                enqueueSnackbar(api.message, {
                    variant: 'success',
                });
                setInputLink({
                    name: "",
                    gateway: "",
                    host: "",
                    is_wireless: "",
                })
                navigate(from, { replace: true });
            } else {
                if (Array.isArray(api.validationErrors)) {
                    api.validationErrors.map((e, i) => {
                        enqueueSnackbar(e.msg, {
                            variant: 'error',
                        });
                    })
                } else {
                    enqueueSnackbar(api.errors?.message, {
                        variant: 'error',
                    });
                }
            }
        } else {
            const api = await linkService.create(InputLink)

            if (api.success) {
                enqueueSnackbar(api.message, {
                    variant: 'success',
                });
                setInputLink({
                    name: "",
                    gateway: "",
                    host: "",
                    is_wireless: "",
                })
                navigate(from, { replace: true });
            } else {
                if (Array.isArray(api.validationErrors)) {
                    api.validationErrors.map((e, i) => {
                        enqueueSnackbar(e.msg, {
                            variant: 'error',
                        });
                    })
                } else {
                    enqueueSnackbar(api.errors?.message, {
                        variant: 'error',
                    });
                }
            }
        }
    }

    return (
        <Grid container spacing={5}>
            <Grid item md={3}>
            </Grid>
            <Grid item md={6}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="nama">Nama Link</InputLabel>
                            <Input
                                onChange={handleChange}
                                name="name"
                                label="Nama link"
                                id="nama"
                                value={InputLink?.name ? InputLink?.name : ""}
                                placeholder="Nama link"
                                size="small"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton disabled
                                        >
                                            {<LeakAddIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel id="gateway">Pilih perangkat gateway</InputLabel>
                            <Select
                                labelId="gatewayDevice"
                                id="selectgatewayDevice"
                                value={InputLink?.gateway ? InputLink?.gateway : 0}
                                label="Pilih Perangkat"
                                onChange={handleChange}
                                name="gateway"
                            >
                                <MenuItem selected disabled value={0}>- Pilih Perangkat -</MenuItem>
                                {
                                    gateway?.map((e, i) => {
                                        return <MenuItem key={'gw' + i} value={e.id}>{e.name?.toUpperCase()}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel id="host">Pilih perangkat client</InputLabel>
                            <Select
                                labelId="hostDevice"
                                id="selecthostDevice"
                                value={InputLink?.host ? InputLink?.host : 0}
                                label="Pilih Perangkat"
                                onChange={handleChange}
                                name="host"
                            >
                                <MenuItem selected disabled value={0}>- Pilih Perangkat -</MenuItem>
                                {
                                    devices?.map((e, i) => {
                                        return <MenuItem key={'host' + i} value={e.id}>{e.name?.toUpperCase()}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={12}>
                        <FormControl fullWidth variant="standard">
                            <FormControlLabel control={<Switch checked={InputLink?.is_wireless === "wireless" ? true : false} onChange={handleChange} name="is_wireless" />} label="Wireless Link" />
                        </FormControl>
                    </Grid>
                    <Grid item md={12}>
                        <Button fullWidth variant="contained" onClick={handleSubmit} aria-label="edit" color="primary">
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={3}>
            </Grid>
        </Grid>
    );
}