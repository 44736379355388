import { Helmet } from "react-helmet-async";
const PageServerError = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Helper - Internal Server Error</title>                
            </Helmet>
            <h1>500 Server Error</h1>
        </>
    )
}

export default PageServerError