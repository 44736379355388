import * as React from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { useSnackbar } from 'notistack';
import BackdropApp from '../../components/BackdropApp';
import { Grid, Typography } from '@mui/material';
import AuthService from '../../services/auth.service'
import { Helmet } from "react-helmet-async";

const ResendVerifyLink = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [loaded, setloaded] = React.useState(true)

    const handleSubmit = async () => {
        setloaded(false)
        const auth = await AuthService.sendVerifyEmail()
        if (auth.success === false) {
            setloaded(true)
            enqueueSnackbar(auth.errors?.message, {
                variant: 'error',
            })
        } else {
            setloaded(true)
            enqueueSnackbar("email sent to : " + auth.payload?.email, {
                variant: 'success',
            })
        }
    }

    React.useEffect(() => {
        if (!loaded) {
            return <BackdropApp />
        }
    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Helper - Send Verification</title>
            </Helmet>
            <Grid container spacing={5}>
                <Grid item md={4}></Grid>
                <Grid item md={4}>
                    <Typography align="center" >
                        Klik tombol dibawah ini untuk mengirim ulang link verifikasi
                    </Typography><br />
                    <FormControl fullWidth variant="standard">
                        <Button onClick={handleSubmit} variant="contained">Send verify link</Button>
                    </FormControl>
                </Grid>
                <Grid item md={4}></Grid>
            </Grid>
            {!loaded ? <BackdropApp /> : null}
        </>
    )
}

export default ResendVerifyLink