import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "../../App.css";
import ShowHomepass from "./ShowHomepass"
import homepassService from "../../services/homepass.service"
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import { IconButton } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useSnackbar } from 'notistack';
import haversineService from "../../services/haversine.service";
import BackdropApp from "../../components/BackdropApp";
import { useNavigate } from 'react-router-dom';
import PageUnauthorized from '../../pages/403'
import ShowTracking from "./ShowTracking";

function Map() {
  const navigate = useNavigate();
  const [homepass, sethomepass] = useState([])
  const [loaded, setloaded] = useState(false)
  const [zoom, setzoom] = useState(9)
  const [err, seterr] = useState(false)
  const [center, setcenter] = useState([-7.242875, 109.217572])
  const [position, setPosition] = useState(center)
  const [check, setcheck] = useState(false)
  const [search, setsearch] = useState("")
  const [tracking, settracking] = useState([{}])

  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useQuery("repoData", async () => {
    const data = await homepassService.getAll()

    if (data.success === false) {
      enqueueSnackbar(data.errors?.message, {
        variant: 'error',
      })
      if (data.errors?.message === "Email unverified") {
        navigate("/resend-verify")
      } else {
        navigate("/error")
      }
    } else {
      setloaded(true)
      return data
    }
  }
  );

  async function getData() {
    const data = await homepassService.getAll()

    sethomepass(data.payload)
  }

  useEffect(() => {
    getData()
  }, [])


  if (err) return <PageUnauthorized />

  if (error) return "An error has occurred: " + error.message;


  const handleSearch = (event) => {
    setsearch(event.target.value)
  }

  const submitSearch = async () => {
    let latlong = (search.split(","))
    let lat = parseFloat(latlong[0])
    let lon = parseFloat(latlong[1])
    let hasilTracking = []
    if (isLatitude(lat) && isLongitude(lon)) {
      hasilTracking.push({
        src: [lat, lon],                        
      })
      settracking(hasilTracking)
      setzoom(15)
      setPosition([lat, lon])
      setcheck(true)    
    } else {
      enqueueSnackbar("Format koordinat salah", {
        variant: 'error',
      });
      setcheck(false)
    }
  }

  const resetMap = async () => {
    setzoom(9)
    setPosition(center)

    setcheck(false)
  }

  function isLatitude(lat) {
    return isFinite(lat) && Math.abs(lat) <= 90;
  }

  function isLongitude(lng) {
    return isFinite(lng) && Math.abs(lng) <= 180;
  }

  if (!loaded) {
    return <BackdropApp />
  } else {
    return (
      <>
        <Stack
          m={2}
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Paper xs={12}>
            <InputBase
              onChange={handleSearch}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Cek coverage wireless"
              inputProps={{ 'aria-label': 'Cek coverage wireless' }}
            />
            <IconButton onClick={submitSearch} type="submit" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <IconButton onClick={resetMap} type="submit" sx={{ p: '10px' }} aria-label="search">
              <RestartAltIcon />
            </IconButton>
          </Paper>
        </Stack>
        <MapContainer center={center} zoom={zoom} scrollWheelZoom={true}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />          
          <ShowHomepass data={homepass || []} />          
          {
            check ? <ShowTracking tracking={tracking} data={position} zoom={zoom} /> : null
          }
        </MapContainer>
      </>
    );
  }
}

export default Map;