import {
    useParams,    
} from "react-router-dom";
import { useSnackbar } from 'notistack';
import { useState } from "react";
import { useEffect } from 'react';
import sitesService from '../../services/sites.service';
import PageNotFound from "../../pages/404"
import BackdropApp from "../../components/BackdropApp";
import WalletForm from "../../components/wallet/form";
import walletService from "../../services/wallet.service";

const EditWallet = () => {
    let { walletId } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const [edited, setedited] = useState("")
    const [loaded, setloaded] = useState(false)

    const geteditedData = async () => {
        const data = await walletService.get(walletId)
        if (!data.success) enqueueSnackbar(data.message, {
            variant: 'error',
        });
        setedited(data.payload)
        setloaded(true)
    }

    useEffect(() => {
        geteditedData()        
    }, [])


    if (edited) {
        return (
            <>
                <WalletForm wallet={edited}/>
            </>
        )
    } else {
        if (loaded) {
            return (
                <>
                    <PageNotFound />
                </>
            )
        } else {
            return (
                <>                
                    <BackdropApp loaded={loaded} />
                </>
            )

        }
    }
}

export default EditWallet