import LinkForm from "../../components/links/form"

const CreateLink = () => {
    return (
        <>
            <LinkForm />
        </>
    )
}

export default CreateLink