import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Visibility from '@mui/icons-material/Visibility';
import { useSnackbar } from 'notistack';
import BackdropApp from '../../components/BackdropApp';
import { Typography } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
    Link,
    useParams,
} from "react-router-dom";
import AuthService from '../../services/auth.service'
import {
    useNavigate,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";

const ResetPasswordPage = () => {
    let navigate = useNavigate();
    let { resetToken } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [loaded, setloaded] = React.useState(true)
    const [values, setValues] = React.useState({
        password: '',
        passwordConfirmation: '',
        showPassword: false,
    });
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async () => {
        setloaded(false)
        const auth = await AuthService.resetPassword(values, resetToken)
        if (auth.success === false) {
            setloaded(true)
            if (Array.isArray(auth.validationErrors)) {
                auth.validationErrors.map((e, i) => {
                    enqueueSnackbar(e.msg, {
                        variant: 'error',
                    });
                })
            } else {
                enqueueSnackbar(auth.errors?.message, {
                    variant: 'error',
                });
            }
        } else {
            setloaded(true)
            enqueueSnackbar("Password successfully changed", {
                variant: 'success',
            })

            navigate('/login')
        }

    }

    async function hitVerify() {
        const data = await AuthService.verifyResetToken(resetToken)
        if (data.success) {
            enqueueSnackbar("Masukkan password baru anda", {
                variant: 'success',
            });
            enqueueSnackbar("Verified Token", {
                variant: 'success',
            });
        } else {
            enqueueSnackbar("Token invalid", {
                variant: 'error',
            });
        }
    }

    React.useEffect(() => {
        hitVerify()
        if (!loaded) {
            return <BackdropApp />
        }
    }, [loaded])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Helper - Reset passowrd</title>
            </Helmet>
            <Stack
                component="form"
                sx={{
                    width: '80%',
                    padding: 2,
                }}
                alignContent="center"
                alignItems="center"
                spacing={2}
                noValidate
                autoComplete="off"
            >
                <Typography align="center" >
                    Masukkan password baru Anda, disarankan menggunakan password yang aman, terdiri dari perpaduan huruf kapital, angka dan simbol yang hanya bisa diingat oleh Anda
                </Typography><br />
                <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">Retype Password</InputLabel>
                    <Input
                        id="-passwordConfirmation"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.passwordConfirmation}
                        onChange={handleChange('passwordConfirmation')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl fullWidth variant="standard">
                    <Button onClick={handleSubmit} variant="contained">SEND RESET PASSWORD</Button>
                </FormControl>
                <Typography align="center">
                    <Link to="/login"> Klik disini untuk login</Link>
                </Typography>
            </Stack>
            {!loaded ? <BackdropApp /> : null}
        </>
    )
}

export default ResetPasswordPage