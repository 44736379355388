import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function BackdropApp(props) {
    return (
        <Backdrop
            sx={{ color: '#1976d2', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={!props.loaded}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}