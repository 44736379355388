import { Helmet } from "react-helmet-async";

const PageUnauthorized = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Helper - Page Not Found</title>
            </Helmet>
            <h1>403 Forbidden</h1>
        </>
    )
}

export default PageUnauthorized