import React, { useState } from "react";
import L from "leaflet";
import "./ShowCrimes.css";
import { Marker, useMap, Popup, Polyline } from "react-leaflet";

const cuffs = new L.Icon({
  iconUrl: "/pin.png",
  iconSize: [45, 45],
});

function ShowTracking(props) {
  const [zoom, setZoom] = useState(props.zoom);
  const [position, setPosition] = useState(props.data);
  const map = useMap();

  React.useEffect(() => {    
    map.setView(props.data, props.zoom)
    setPosition(props.data)
    setZoom(props.zoom)    
  }, [map,props.data]);

  return (
    <>
      {
        props?.tracking?.map((e, i) => {
          return <Polyline key={`line${i}`} positions={[
            e.src,
            e.dst,
          ]} color={'blue'}
            children={<Popup>
              {e.dstName} <br />
              {e.bearing} &deg; <br />
              {e.jarak} km
            </Popup>}
          />
        })
      }
      <Marker
        position={position}
        icon={cuffs}
        zoom={zoom}

      >
        <Popup>
          Calon customer
        </Popup>
      </Marker>
      );
    </>
  );
}

export default ShowTracking;