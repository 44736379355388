import http from "../common/http-common";

class walletDataService {
  getAll() {
    const data = http.get("/wallet/read/")

    return data
  }

  get(id) {
    const data = http.get(`/wallet/read/${id}`)

    return data
  }

  create(form) {
    const data = http.post("/wallet/create", form)

    return data
  }

  update(form, id) {    
    const data = http.put(`/wallet/${id}/update`, form)

    return data
  }

  delete(id) {
    const data = http.delete(`/wallet/${id}/delete`)

    return data
  }

}

export default new walletDataService();