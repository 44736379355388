import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Outlet, Link } from "react-router-dom";
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';

export default function AuthTopNav() {
  const styles = {
    paperContainer: {
      backgroundImage: `url(https://image.freepik.com/free-vector/blue-fluid-background_53876-114597.jpg)`,
      width: '100%',
      height: '100vh'
    }
  };

  return (
    <>
      <Paper style={styles.paperContainer}>
        <Box
          sx={{
            pt: 10,
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            flexWrap: 'wrap',
            '& > :not(style)': {
              m: 1,
              width: "80%",
              height: 400,
            },
          }}
        >
          <Paper elevation={3} sx={
            {
              display: 'flex',
            }
          }>
            <Grid container spacing={2}>
              <Grid item md={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
                <CardMedia                  
                  component="img"
                  width="100%"
                  height="100%"
                  image="https://image.freepik.com/free-vector/business-team-brainstorm-idea-lightbulb-from-jigsaw-working-team-collaboration-enterprise-cooperation-colleagues-mutual-assistance-concept-pinkish-coral-bluevector-isolated-illustration_335657-1651.jpg"
                  alt="green iguana"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Outlet />
              </Grid>
            </Grid>
            {/* <Box sx={{
              display: 'flex',
              flexWrap: 'wrap',
              '& > :not(style)': {
                width: "100%",
                height: "100%",
              },
            }}>

            </Box> */}

          </Paper>
        </Box>
      </Paper>
    </>
  );
}
