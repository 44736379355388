import WalletForm from "../../components/wallet/form"

const CreateWallet = () => {
    return (
        <>
            <WalletForm />
        </>
    )
}

export default CreateWallet