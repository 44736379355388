import {
    useParams,
} from "react-router-dom";
import { useSnackbar } from 'notistack';
import { useState } from "react";
import { useEffect } from 'react';
import PageNotFound from "../../pages/404"
import linkService from "../../services/link.service";
import BackdropApp from "../../components/BackdropApp";
import CustomerForm from "../../components/customers/form";

const EditCustomer = () => {
    let { clientId } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const [edited, setedited] = useState("")
    const [loaded, setloaded] = useState(false)

    const getData = async () => {
        const data = await linkService.get(clientId)
        if (!data.success) enqueueSnackbar(data.message, {
            variant: 'error',
        });
        setedited(data.payload)
        setloaded(true)
    }

    useEffect(() => {
        getData()
    }, [])


    if (edited) {
        return (
            <>
                <CustomerForm clients={edited} />
            </>
        )
    } else {
        if (loaded) {
            return (
                <>
                    <PageNotFound />
                </>
            )
        } else {
            return (
                <>
                    <BackdropApp loaded={loaded} />
                </>
            )

        }
    }
}

export default EditCustomer