import http from "../common/http-common";

class BackupDataService {
  getAll() {
    const data = http.get("/backup-log/read")

    return data
  }

  findFile(req) {
    const year = req?.year
    const month = req?.month
    const date = req?.date
    const site = req?.site
    const device = req?.device
    let path = '/backup/find-backup?'
    if (year) path = `${path}year=${year}`
    if (month) path = `${path}&month=${month}`
    if (date) path = `${path}&date=${date}`
    if (site) path = `${path}&site=${site}`
    if (device) path = `${path}&device=${device}`

    const data = http.get(path)
    return data
  }

  downloadFile(req) {
    const year = req?.year
    const month = req?.month
    const date = req?.date
    const site = req?.site
    const device = req?.device
    const filename = req?.filename
    let path = '/backup/download?'
    if (year) path = `${path}year=${year}`
    if (month) path = `${path}&month=${month}`
    if (date) path = `${path}&date=${date}`
    if (site) path = `${path}&site=${site}`
    if (device) path = `${path}&device=${device}`
    if (filename) path = `${path}&filename=${filename}`
    
    const data = http.get(path, { responseType: 'blob' })
    return data
  }
}

export default new BackupDataService();