import { Navigate, useLocation, } from "react-router-dom";
import { bindActionCreators } from 'redux'
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux";
import { actionCreators } from "../state/index"
import jwtDecode from "jwt-decode";
import { useSnackbar } from 'notistack';


export function PrivateAuth({ children }) {
    let location = useLocation();
    const isLogin = useSelector((state) => state.auth);    
    const dispatch = useDispatch();
    const { setLoggedIn,setAccount } = bindActionCreators(actionCreators, dispatch);

    const { enqueueSnackbar } = useSnackbar();

    const token = localStorage.getItem('access_token');
    if (!token) {
        enqueueSnackbar("Anda tidak mempunyai token, silahkan login", {
            variant: 'error',
        })
        return <Navigate to="/login" state={{ from: location }} />;
    }

    const { exp, email } = jwtDecode(token)
    
    const expirationTime = (exp * 1000) - 60000
    if (Date.now() >= expirationTime) {
        enqueueSnackbar("Sesi anda habis, silahkan login ulang", {
            variant: 'error',
        })
        localStorage.removeItem('access_token')
        return <Navigate to="/login" state={{ from: location }} />;
    }
    setAccount(email)
    setLoggedIn(token ? true : false)

    if (isLogin === false) {
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return children;
}

export function RouteNoAuth({ children }) {
    const isLogin = useSelector((state) => state.auth);
    const token = localStorage.getItem('access_token');

    const dispatch = useDispatch();

    const { setLoggedIn } = bindActionCreators(actionCreators, dispatch);
    setLoggedIn(token ? true : false)

    if (isLogin === true) {
        return <Navigate to="/" />;
    }

    return children;
}