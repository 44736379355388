import * as React from 'react';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import sitesService from '../../services/sites.service';
import sitesCatService from '../../services/site-category.service';
import { useSnackbar } from 'notistack';
import { useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import {
    useLocation,
    useNavigate,
} from "react-router-dom";
import PushPinIcon from '@mui/icons-material/PushPin';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';

export default function SiteForm(props) {
    const [sitecat, setsitecat] = useState([{}])
    const [InputSite, setInputSite] = useState({})
    
    let navigate = useNavigate();
    let location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const sites = props.sites

    let from = location.state?.from?.pathname || "/sites";    

    useEffect(() => {
        ambilSiteCategory()

        if (sites !== undefined) {

            return setInputSite({
                id: sites?.id,
                name: sites?.name,
                latitude: sites?.latitude,
                longitude: sites?.longitude,
                address: sites?.address,
                category: sites?.category
            })
        }
    }, [sites])

    const handleChange = (e) => {
        setInputSite({
            ...InputSite,
            [e.target.name]: e.target.value
        })
    }

    const ambilSiteCategory = async () => {
        const data = await sitesCatService.getAll()
        setsitecat(data.payload)
    }


    const handleSubmit = async () => {
        setInputSite({
            ...InputSite,
        })
        if (sites) {
            const api = await sitesService.update(InputSite, sites.id)

            if (api.success) {
                enqueueSnackbar(api.message, {
                    variant: 'success',
                });
                setInputSite({
                    name: "",
                    latitude: "",
                    longitude: "",
                    address: "",
                    category: ""
                })
                navigate(from, { replace: true });
            } else {
                if (Array.isArray(api.validationErrors)) {
                    api.validationErrors.map((e, i) => {
                        enqueueSnackbar(e.msg, {
                            variant: 'error',
                        });
                    })
                } else {
                    enqueueSnackbar(api.errors?.message, {
                        variant: 'error',
                    });
                }
            }
        } else {
            const api = await sitesService.create(InputSite)

            if (api.success) {
                enqueueSnackbar(api.message, {
                    variant: 'success',
                });
                setInputSite({
                    name: "",
                    latitude: "",
                    longitude: "",
                    address: "",
                    category: ""
                })
                navigate(from, { replace: true });
            } else {
                if (Array.isArray(api.validationErrors)) {
                    api.validationErrors.map((e, i) => {
                        enqueueSnackbar(e.msg, {
                            variant: 'error',
                        });
                    })
                } else {
                    enqueueSnackbar(api.errors?.message, {
                        variant: 'error',
                    });
                }
            }
        }
    }

    return (
        <Grid container spacing={5}>
            <Grid item md={3}>
            </Grid>
            <Grid item md={6}>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="nama">Nama Lokasi</InputLabel>
                            <Input
                                onChange={handleChange}
                                name="name"
                                label="Nama"
                                id="nama"
                                value={InputSite?.name ? InputSite?.name : ""}
                                placeholder="Nama Lokasi"
                                size="small"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton disabled
                                        >
                                            {<AddLocationAltIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel id="siteCategory">Pilih Kategori</InputLabel>
                            <Select
                                labelId="siteCategory"
                                id="selectCategory"
                                value={InputSite?.category ? InputSite?.category : 0}
                                label="Pilih Kategori"
                                onChange={handleChange}
                                name="category"
                            >
                                <MenuItem disabled selected value={0}>- Pilih Kategori -</MenuItem>
                                {
                                    sitecat?.map((e, i) => {
                                        return <MenuItem key={i} value={e.id}>{e.name?.toUpperCase()}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="latitude">Garis Lintang</InputLabel>
                            <Input
                                onChange={handleChange}
                                value={InputSite?.latitude ? InputSite?.latitude : ""}
                                name="latitude"
                                label="Decimal latitude"
                                id="latitude"
                                placeholder="Masukkan garis lintang dalam decimal"
                                variant="filled"
                                size="small"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton disabled
                                        >
                                            {<PushPinIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="standard-adornment-password">Garis Bujur</InputLabel>
                            <Input
                                onChange={handleChange}
                                value={InputSite?.longitude ? InputSite?.longitude : ""}
                                name="longitude"
                                label="Decimal longitude"
                                id="longitude"
                                placeholder="Masukkan garis bujur dalam decimal"
                                variant="filled"
                                size="small"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton disabled
                                        >
                                            {<PushPinIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <TextareaAutosize
                                minRows={5}
                                onChange={handleChange}
                                name="address"
                                value={InputSite?.address ? InputSite.address : ""}
                                label="Alamat"
                                id="alamat"
                                placeholder="alamat atau patokan"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Button fullWidth variant="contained" onClick={handleSubmit} aria-label="edit" color="primary">
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={3}>
            </Grid>
        </Grid>
    );
}