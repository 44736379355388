import http from "../common/http-common";


class SiteCatService {
  getAll() {    
    const data = http.get("/category/read")

    return data
  }  
}

export default new SiteCatService();