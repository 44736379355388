import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import EmailIcon from '@mui/icons-material/Email';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import authService from '../../services/auth.service';
import { useSnackbar } from 'notistack';
import { bindActionCreators } from 'redux'
import { actionCreators } from "../../state/index"
import { useDispatch } from "react-redux";
import BackdropApp from '../../components/BackdropApp';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import {
    useNavigate,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";

const RegisterPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [loaded, setloaded] = React.useState(true)
    const dispatch = useDispatch();
    const { setLoggedIn } = bindActionCreators(actionCreators, dispatch);
    let navigate = useNavigate();

    const [values, setValues] = React.useState({
        email: '',
        password: '',
        passwordConfirmation: '',
        phone: '',
        firstname: '',
        lastname: '',
        showPassword: false,
    });
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (e) => {
        setloaded(false)

        e.preventDefault();
        if (values?.email?.split('@')[1] !== 'citra.net.id') {
            return enqueueSnackbar('Email dilarang', {
                variant: 'error',
            });
        }
        const register = await authService.register(values)
        if (register.success) {
            localStorage.setItem('access_token', register.payload.access_token)
            enqueueSnackbar(register.message, {
                variant: 'success',
            });
            enqueueSnackbar('Silahkan login untuk verifikasi', {
                variant: 'success',
            });
            setloaded(true)
            navigate('/login')
        } else {
            setloaded(true)
            if (Array.isArray(register.validationErrors)) {
                register.validationErrors.map((e, i) => {
                    enqueueSnackbar(e.msg, {
                        variant: 'error',
                    });
                })
            } else {
                enqueueSnackbar(register.errors?.message, {
                    variant: 'error',
                });
            }
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Helper - Register</title>
            </Helmet>
            <Grid container
                component="form"
                sx={{
                    width: '100%',
                    padding: 2,
                }}
                justifyContent="center"
                spacing={2}
                noValidate
                autoComplete="off"
            >
                <Grid item md={6}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel htmlFor="firstname">First Name</InputLabel>
                        <Input
                            id="firstname"
                            type="text"
                            value={values.firstname}
                            onChange={handleChange('firstname')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton disabled
                                    >
                                        {<AccountCircleIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel htmlFor="firstname">Last Name</InputLabel>
                        <Input
                            id="lastname"
                            type="text"
                            value={values.lastname}
                            onChange={handleChange('lastname')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton disabled
                                    >
                                        {<AccountCircleIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel htmlFor="standard-adornment-password">email</InputLabel>
                        <Input
                            id="email"
                            type="text"
                            value={values.email}
                            onChange={handleChange('email')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton disabled
                                    >
                                        {<EmailIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel htmlFor="standard-adornment-password">Phone</InputLabel>
                        <Input
                            id="phone"
                            type="text"
                            value={values.phone}
                            onChange={handleChange('phone')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton disabled
                                    >
                                        {<PhoneIphoneIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item md={12}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange('password')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item md={12}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel htmlFor="standard-adornment-password">Retype Password</InputLabel>
                        <Input
                            id="-passwordConfirmation"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.passwordConfirmation}
                            onChange={handleChange('passwordConfirmation')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item align="center" md={12}>
                    <FormControl fullWidth variant="standard">
                        <Button onClick={handleSubmit} variant="contained">Register</Button>
                    </FormControl>
                </Grid>
                <Grid item md={12}
                >
                    <Typography align="center" >
                        Sudah punya akun? Silahkan <Link to="/login">login</Link>
                    </Typography>
                </Grid>
            </Grid>
            {!loaded ? <BackdropApp /> : null}
        </>
    )
}

export default RegisterPage