import http from "../common/http-auth";

class AuthService {
  login(params) {
    const data = http.post("/auth/login", params)

    return data
  }
  sendResetPassword(params) {
    const data = http.post(`/auth/forget-password`,params);

    return data
  }
  sendVerifyEmail() {
    const data = http.post(`/auth/email-verify/send-link`);

    return data
  }
  verifyEmail(id) {
    const data = http.get(`/auth/email-verify/${id}`);

    return data
  }
  verifyResetToken(token) {
    const data = http.get(`/auth/password-reset/${token}`);

    return data
  }
  resetPassword(params,token) {
    const data = http.put(`/auth/password-reset/${token}`,params);

    return data
  }
  register(data) {
    return http.post("/user/register", data);
  }
}

export default new AuthService();