import * as React from 'react';
import {
    useParams,
} from "react-router-dom";
import authService from '../../services/auth.service';
import { useSnackbar } from 'notistack';
import BackdropApp from '../../components/BackdropApp';
import {
    useLocation,
    useNavigate,
} from "react-router-dom";

const VerifyUser = () => {
    let { jwtToken } = useParams();
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    async function hitVerify() {
        const data = await authService.verifyEmail(jwtToken)
        
        if (data.payload?.verified) {
            enqueueSnackbar("User verified", {
                variant: 'success',
            });
            navigate('/login', { replace: true });
        } else {
            if (data.errors?.message?.name === "JsonWebTokenError") {
                enqueueSnackbar(data.errors?.message?.message, {
                    variant: 'error',
                });
            } else {
                enqueueSnackbar(data.errors?.message, {
                    variant: 'error',
                });
            }
            navigate('/login', { replace: true });
        }
    }

    React.useEffect(() => {
        hitVerify()
    }, [])
    return (
        <>
            Verifying...
            <BackdropApp />
        </>
    )
}

export default VerifyUser