function deg2rad(deg) {
    return deg * (Math.PI / 180)
}

class Haversine {
    getDistance(lat1, lon1, lat2, lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2 - lat1);  // deg2rad below
        var dLon = deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
            ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return Math.round(d * 100) / 100;
    }
    getBearing(lat1, lon1, lat2, lon2) {
        lat1 = deg2rad(lat1);
        lon1 = deg2rad(lon1);
        lat2 = deg2rad(lat2);
        lon2 = deg2rad(lon2);
        const lonDelta = lon2 - lon1;
        let y = Math.sin(lonDelta) * Math.cos(lat2);
        let x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lonDelta);
        let brng = Math.atan2(y, x);
        brng = brng * (180 / Math.PI);

        if (brng < 0) { brng += 360; }

        return Math.round(brng * 100) / 100;
    }

}

export default new Haversine()