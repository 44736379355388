import http from "../common/http-common";

class HomepassDataService {
  getAll() {
    const data = http.get("/homepass/read/")

    return data
  }

  get(id) {
    const data = http.get(`/homepass/read/${id}`)

    return data
  }

  create(form) {
    const data = http.post("/homepass/create", form)

    return data
  }

  update(form, id) {
    const data = http.put(`/homepass/${id}/update`, form)

    return data
  }

  delete(id) {
    const data = http.delete(`/homepass/delete`, {
      data: {
        id:id
      }
    })

    return data
  }

}

export default new HomepassDataService();