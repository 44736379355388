import http from "../common/http-common";

class MikrotikService {
  getconf(host) {
    const data = http.get("/mikrotik/config?host=" + host)

    return data
  }
}

export default new MikrotikService();