import FolderIcon from '@mui/icons-material/Folder';
import Button from '@mui/material/Button';

const FolderButton = (props) => {
    const { name, event } = props    
    return (
        <Button onClick={event} style={{ minWidth: '100%', minHeight: '100%',maxWidth:'100%' }} size='large' variant="outlined" startIcon={<FolderIcon />}>
            {name}
        </Button>
    )

}

export default FolderButton