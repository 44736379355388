import * as React from 'react';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useState } from "react";
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import {
    useLocation,
    useNavigate,
} from "react-router-dom";
import walletService from '../../services/wallet.service';
import LinkIcon from '@mui/icons-material/Link';

export default function WalletForm(props) {
    const [Values, setValues] = useState({
        id: "",
        name: "",
        url: "",
        note: "",
    })

    let navigate = useNavigate();
    let location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const wallet = props.wallet

    let from = location.state?.from?.pathname || "/wallet";

    useEffect(() => {
        if (wallet !== undefined) {

            return setValues({
                id: wallet?.id,
                name: wallet?.name,
                url: wallet?.url,
                note: wallet?.note,
            })
        }
    }, [wallet])

    const handleChange = (e) => {
        setValues({
            ...Values,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async () => {
        setValues({
            ...Values,
        })
        if (wallet) {
            const api = await walletService.update(Values, wallet.id)

            if (api.success) {
                enqueueSnackbar(api.message, {
                    variant: 'success',
                });

                navigate(from, { replace: true });
            } else {
                if (Array.isArray(api.validationErrors)) {
                    api.validationErrors.map((e, i) => {
                        enqueueSnackbar(e.msg, {
                            variant: 'error',
                        });
                    })
                } else {
                    enqueueSnackbar(api.errors?.message, {
                        variant: 'error',
                    });
                }
            }
        } else {
            const api = await walletService.create(Values)

            if (api.success) {
                enqueueSnackbar(api.message, {
                    variant: 'success',
                });

                navigate(from, { replace: true });
            } else {
                if (Array.isArray(api.validationErrors)) {
                    api.validationErrors.map((e, i) => {
                        enqueueSnackbar(e.msg, {
                            variant: 'error',
                        });
                    })
                } else {
                    enqueueSnackbar(api.errors?.message, {
                        variant: 'error',
                    });
                }
            }
        }
    }

    return (
        <Grid container spacing={5}>
            <Grid item md={3}>
            </Grid>
            <Grid item md={6}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="nama">Nama</InputLabel>
                            <Input
                                onChange={handleChange}
                                name="name"
                                label="Nama data"
                                id="nama"
                                value={Values?.name ? Values?.name : ""}
                                placeholder="Misal nama perangkat atau nama client"
                                variant="filled"
                                size="small"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton disabled
                                        >
                                            {<AccountCircleIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="url">URL</InputLabel>
                            <Input
                                onChange={handleChange}
                                name="url"
                                label="url"
                                id="url"
                                value={Values?.url ? Values?.url : ""}
                                placeholder="Domain atau IP"
                                size="small"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton disabled
                                        >
                                            {<LinkIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <FormControl fullWidth variant="standard">
                            <TextareaAutosize
                                minRows={5}
                                onChange={handleChange}
                                name="note"
                                value={Values?.note ? Values.note : ""}
                                label="Catatan"
                                id="catatan"
                                placeholder="Catatan yang mau disimpan"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Button fullWidth variant="contained" onClick={handleSubmit} aria-label="edit" color="primary">
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={3}>
            </Grid>
        </Grid>
    );
}